@font-face {
font-family: '__primaryFont_1f12e8';
src: url(/_next/static/media/ae6c038217708412-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__primaryFont_1f12e8';
src: url(/_next/static/media/64bba4165c476150-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__primaryFont_1f12e8';
src: url(/_next/static/media/cff628a8939ecc88-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__primaryFont_1f12e8';
src: url(/_next/static/media/ce347676821809ae-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__primaryFont_1f12e8';
src: url(/_next/static/media/68d966b4a396d6eb-s.p.woff) format('woff');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__primaryFont_Fallback_1f12e8';src: local("Arial");ascent-override: 57.59%;descent-override: 16.01%;line-gap-override: 14.72%;size-adjust: 135.87%
}.__className_1f12e8 {font-family: '__primaryFont_1f12e8', '__primaryFont_Fallback_1f12e8'
}

